import './App.css';
import Home from "./containers/Home/Home";
import {Route, Routes} from "react-router-dom";
import Layout from "./components/Layout/Layout";
import {about, events, main, news, videos, library, resources} from "./paths";
import News from "./containers/News/News";
import About from "./containers/About/About";
import Events from "./containers/Events/Events";
import Videos from "./containers/Videos/Videos";
import Library from "./containers/Library/Library";
import Resources from "./containers/Resources/Resources";
import DetailedEvent from "./containers/DetailedEvent/DetailedEvent";
import DetailedNews from "./containers/DetailedNews/DetailedNews";


function App() {
    return (
        <Layout>
            <Routes>
                <Route path={main} element={<Home/>}/>
                <Route path={news} element={<News/>}/>
                <Route path={about} element={<About/>}/>
                <Route path={events} element={<Events/>}/>
                <Route path={videos} element={<Videos/>}/>
                <Route path={library} element={<Library/>}/>
                <Route path={resources} element={<Resources/>}/>
                <Route path={'/events/:id'} element={<DetailedEvent/>}/>
                <Route path={'/news/:id'} element={<DetailedNews/>}/>
            </Routes>
        </Layout>
    );
}

export default App;
