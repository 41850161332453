import React, {useEffect, useMemo, useState} from 'react';
import {Container, Grid, useMediaQuery} from "@mui/material";
import MiniSlider from "../../components/MiniSlider/MiniSlider";
import ShareComponent from "../../components/ShareComponent/ShareComponent";
import shareIcon from "../../assets/images/Base.svg";
import shareArrow from "../../assets/images/Arrow.svg";
import print from "../../assets/images/document.svg";
import "swiper/css";
import "swiper/css/pagination";
import BigSlider from "../../components/BigSlider/BigSlider";
import {Link, useParams} from "react-router-dom";
import axiosApi from "../../axiosApi";
import {useTranslation} from "react-i18next";
import Preloader from "../../components/Preloader/Preloader";
import DOMPurify from "dompurify";
import {useDispatch, useSelector} from "react-redux";
import {fetchNewsCategoryRequest, setCatId} from "../../store/actions/newsActions";

const DetailedNews = () => {
    const mobileScreen = useMediaQuery('(max-width:504px)');
    const {id} = useParams();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    const [openModal, setOpenModal] = useState(false);
    const [shareShow, setShareShow] = useState(false);

    const [loading, setLoading] = useState(false);
    const [singleNews, setSingleNews] = useState({});
    const [error, setError] = useState(null);


    const [similarNews, setSimilarNews] = useState([]);

    const tabId = useSelector(state => state.news.tabId);
    const category = useSelector(state => state.news.category);

    console.log(tabId)

    useEffect(() => {
        dispatch(fetchNewsCategoryRequest());
    }, [])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [id])


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const result = await axiosApi.get('/news/news-info/' + id + '/');
                const resultSimilar = await axiosApi.get('/news/similar-news-info/' + id + '/');
                setSingleNews(result.data);
                setSimilarNews(resultSimilar.data.results)
                setLoading(false)
            } catch (err) {
                setError(err);
                setLoading(false)
            }
        };
        fetchData();
    }, [id])


    function getShorVersion(string) {
        const words = string.split(" ");
        if (words.length > 5) {
            return words.slice(0, 5).join(" ");
        } else {
            return string;
        }
    }

    const breadcrumbs = useMemo(() => {
        return (
            <p className="path-text">
                <Link to={'/'}>{t('main')}</Link> /
                <Link to={'/news'}> {t('news')}</Link> /

                {Object.keys(singleNews).length > 0 && (
                    <>
                        {i18n.language === 'ky' && (
                            <span>{getShorVersion(singleNews.title_ky)}...</span>
                        )}
                        {i18n.language === 'ru' && (
                            <span>{getShorVersion(singleNews.title_ru)}...</span>
                        )}
                        {i18n.language === 'en' && (
                            <span>{getShorVersion(singleNews.title_en)}...</span>
                        )}
                    </>
                )}

            </p>
        )

    }, [singleNews, i18n.language]);


    const handlePrint = () => {
        window.print();
    };


    return (
        <>
            {loading ? (
                <Preloader/>
            ) : (
                <>
                    {Object.keys(singleNews).length > 0 && (
                        <Container maxWidth={false} sx={{maxWidth: "1368px"}}>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}
                                  sx={{marginTop: mobileScreen ? "20px" : "52px"}}>
                                <Grid item>
                                    <h4 className="block-title">{t('news')}</h4>
                                </Grid>
                                {/*<Grid item>*/}
                                {/*    <button className={"show-more-btn"}>Показать все</button>*/}
                                {/*</Grid>*/}

                            </Grid>
                            <div className="blue-line"></div>
                            <div className={"line"}></div>

                            {breadcrumbs}

                            <div className={"print-only"}>

                                {i18n.language === 'ky' && (
                                    <h3 className={'commission-title'}>{singleNews.title_ky}</h3>
                                )}
                                {i18n.language === 'ru' && (
                                    <h3 className={'commission-title'}>{singleNews.title_ru}</h3>
                                )}
                                {i18n.language === 'en' && (
                                    <h3 className={'commission-title'}>{singleNews.title_en}</h3>
                                )}


                                <div className="commission-inner">
                                    <div className="slider-float-box">
                                        <MiniSlider id={"news"} pictures={singleNews.news_slider_image}/>
                                    </div>


                                    {i18n.language === 'ky' && (
                                        <div
                                            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(singleNews.text_ky)}}></div>
                                    )}
                                    {i18n.language === 'ru' && (
                                        <div
                                            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(singleNews.text_ru)}}></div>
                                    )}
                                    {i18n.language === 'en' && (
                                        <div
                                            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(singleNews.text_en)}}></div>
                                    )}

                                </div>

                                <Grid container justifyContent={"space-between"} alignItems={"center"}
                                      sx={{marginBottom: "81px", marginTop: "52px"}}>
                                    <Grid item className="btns-parent">
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "left",
                                            marginRight: "50px"
                                        }} className={"share-btn-div"}>
                                            <button
                                                className="blue-btn"
                                                onClick={() => setShareShow(!shareShow)}
                                            >{t('share')}</button>

                                            <div style={{position: "relative", display: "flex", alignItems: "end"}}>
                                                <img src={shareIcon} alt="shareIcon" className={"share-icon"}/> <img
                                                src={shareArrow} alt="shareIcon" className={"share-arrow"}/>
                                            </div>
                                        </div>

                                        <button className="blue-btn" onClick={handlePrint}>{t('print')} <img src={print}
                                                                                                             alt="print icon"/>
                                        </button>
                                    </Grid>
                                    <Grid item>
                                        <p className="date-text">{singleNews.date}</p>
                                    </Grid>
                                    {shareShow && (
                                        <Grid item xs={12} sx={{marginTop: "10px"}}>
                                            <ShareComponent onClose={() => setShareShow(false)}/>
                                        </Grid>
                                    )}
                                </Grid>
                            </div>
                            <div>
                                <p className='small-titles'>{t('similarNews')}</p>
                                <BigSlider id={'news'} news={similarNews}/>
                            </div>

                        </Container>
                    )}
                </>

            )}
        </>

    );
};

export default DetailedNews;