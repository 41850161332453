import goalsSlice from "../slices/goalsSlice";


export const {
    fetchGoalsRequest,
    fetchGoalsFailure,
    fetchGoalsSuccess,
    getSearchSuccess,
    getSearchFailure,
    getSearchRequest,
    clearSearch

} = goalsSlice.actions