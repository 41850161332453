import React, {useEffect, useState} from 'react';
import './styles.css';
import {Container, Grid} from "@mui/material";
import axiosApi from "../../axiosApi";
import {useNavigate} from "react-router-dom";
import LibrarySingleCard from "../../components/LibrarySingleCard/LibrarySingleCard";
import {library, videos} from "../../paths";
import {useTranslation} from "react-i18next";
import SinglePlayer from "../../components/SinglePlayer/SInglePlayer";
import NewsFive from "../../components/NewsFive/NewsFive";
import {mediaUrl} from "../../config";
import Preloader from "../../components/Preloader/Preloader";
import DOMPurify from "dompurify";
import HomeCharts from "../../components/HomeCharts/HomeCharts";

const Home = () => {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const [info, setInfo] = useState({});
    const [libraries, setLibraries] = useState([]);
    const [video, setVideo] = useState([]);
    const [news, setNews] = useState([]);
    const [resources, setResources] = useState([]);

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const result = await axiosApi.get('/main_page/header/');
                setInfo(result.data);
                const resultLib = await axiosApi.get('/main_page/library/');
                setLibraries(resultLib.data.results);
                const resultVid = await axiosApi.get('/main_page/videos/');
                setVideo(resultVid.data.results);
                const resultNews = await axiosApi.get('/main_page/news/');
                setNews(resultNews.data.results);
                const resultRes = await axiosApi.get('/main_page/resources/');
                setResources(resultRes.data);
                setLoading(false)
            } catch (err) {
                setLoading(false)
                setError(err);
            }
        };
        fetchData();
    }, [])



    return (
        <>
            {loading ? (
                <Preloader/>
            ) : (
                <>
                    {info.length > 0 && (
                        <div className={'main-block'} style={{backgroundImage: `url(${mediaUrl + info[0].image})`}}>
                            <Container maxWidth={false} sx={{maxWidth: "1368px"}}>
                                {i18n.language === 'ky' && (
                                    <h3>{info[0].title_ky}</h3>
                                )}
                                {i18n.language === 'ru' && (
                                    <h3>{info[0].title_ru}</h3>
                                )}
                                {i18n.language === 'en' && (
                                    <h3>{info[0].title_en}</h3>
                                )}

                                <div
                                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(info[0][`text_${i18n.language}`])}}></div>

                            </Container>
                        </div>
                    )}
                    <HomeCharts/>
                    <Container maxWidth={false} sx={{maxWidth: "1368px"}}>
                        <div className={"lib-home"}>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('library')}</h4>
                                </Grid>
                                <Grid item>
                                    <button onClick={() => navigate(library)}
                                            className={"show-more-btn"}>{t('seeAll')}</button>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>

                            <Grid container justifyContent={{xs: "center", sm: "space-between"}}
                                  sx={{marginTop: "20px"}}>
                                {libraries.length > 0 && libraries.map(lib => (
                                    <Grid item xs={12} sm={5.8} md={3.3} key={lib.id}>
                                        <LibrarySingleCard content={lib}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>

                        <div className={"lib-home"}>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('videos')}</h4>
                                </Grid>
                                <Grid item>
                                    <button onClick={() => navigate(videos)}
                                            className={"show-more-btn"}>{t('seeAll')}</button>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>

                            <Grid container justifyContent={{xs: "center", sm: "space-between"}}
                                  sx={{marginTop: "20px"}}>
                                {video.length > 0 && video.map(video => (
                                    <Grid item xs={12} sm={5.8} md={3.85}>
                                        <SinglePlayer video={video}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>

                        <div className={"lib-home"}>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('news')}</h4>
                                </Grid>
                                <Grid item>
                                    <button onClick={() => navigate('/news')}
                                            className={"show-more-btn"}>{t('seeAll')}</button>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>

                            <Grid container justifyContent={{xs: "center", sm: "space-between"}}
                                  sx={{marginTop: "20px"}}>
                                {news.length > 0 && (
                                    <NewsFive news={news}/>
                                )}
                            </Grid>
                        </div>
                        <div className={"lib-home"}>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('resources')}</h4>
                                </Grid>
                                <Grid item>
                                    <button onClick={() => navigate('/resources')}
                                            className={"show-more-btn"}>{t('seeAll')}</button>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>

                            <Grid container justifyContent={{xs: "center", sm: "space-between"}}
                                  sx={{marginTop: "20px"}}>
                                {resources.length > 0 && resources.map(res => (
                                    <Grid container className={"resource-card"} justifyContent={"space-between"}
                                          alignItems={'center'}>
                                        <Grid item xs={12} sm={2} md={2} container
                                              justifyContent={{xs: "center", sm: "space-between"}}
                                              sx={{marginBottom: {xs: "20px", sm: "0"}}}>
                                            <img src={mediaUrl + res.icon} alt="emblem" className='emblem'/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5.5} container
                                              justifyContent={{xs: "center", sm: "space-between"}}
                                              sx={{marginBottom: {xs: "20px", sm: "0"}}} flexDirection={'column'}>

                                            {i18n.language === 'ky' && (
                                                <p className={'resource-title'}>{res.title_ky}</p>
                                            )}
                                            {i18n.language === 'ru' && (
                                                <p className={'resource-title'}>{res.title_ru}</p>
                                            )}

                                            {i18n.language === 'en' && (
                                                <p className={'resource-title'}>{res.title_en}</p>
                                            )}


                                            {/*<div className={"resource-card-inner"}>*/}
                                            {/*    {res.goal_ids.length > 0 && res.goal_ids.map(goal => (*/}
                                            {/*        <img src={mediaUrl + goal.little_icon} alt="goal" key={goal.id}/>*/}
                                            {/*    ))}*/}
                                            {/*</div>*/}
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4.5} className="resource-contact" container
                                              justifyContent={{xs: "center", sm: "right"}}
                                              sx={{marginBottom: {xs: "20px", sm: "0"}}} flexDirection={'column'}>
                                            <a href={res.link} target="_blank" rel="noopener noreferrer">{res.link}</a>

                                            {i18n.language === 'ky' && (
                                                <p>{res.address_ky}</p>
                                            )}
                                            {i18n.language === 'ru' && (
                                                <p>{res.address_ru}</p>
                                            )}
                                            {i18n.language === 'en' && (
                                                <p>{res.address_en}</p>
                                            )}
                                            <p>{t('phone')}: {res.phone}</p>
                                            <p>{t('email')}: {res.email}</p>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </Container>
                </>
            )}

        </>

    );
};

export default Home;