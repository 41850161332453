import React, {useEffect, useState} from 'react';
import './styles.css';
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import NewsFive from "../NewsFive/NewsFive";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {useRef} from "react";


const BigSlider = ({id, news}) => {
    const [fistPageNews, setFirstPageNews] = useState([])
    const [secondPageNews, setSecondPageNews] = useState([])
    const [lastPageNews, setLastPageNews] = useState([])

    const [swiperReady, setSwiperReady] = useState(false);
    const swiperRef = useRef();

    useEffect(() => {
        setFirstPageNews(news.length > 0 && news.length >= 5 && news.slice(0, 5));
        setSecondPageNews(news.length > 0 && news.length >= 10 && news.slice(5, 10));
        setLastPageNews(news.length > 0 && news.length >= 15 && news.slice(10, 15));

    }, [news])


    return (
        <div className={"news-swiper-container"}>
            <Swiper
                ref={swiperRef}
                pagination={{
                    el: `#${id}-big-swiper`,
                    type: "bullets",
                    clickable: true,
                }}
                modules={[Navigation, Pagination]}
                className="mySwiper"
                slidesPerView={1}
                spaceBetween={10}
                navigation={{
                    nextEl: `.swiper-button-next-news`,
                    prevEl: `.swiper-button-prev-news`,
                    clickable: true,
                }}
            >

                {fistPageNews.length > 0 && (
                    <SwiperSlide>
                        <NewsFive news={fistPageNews}/>
                    </SwiperSlide>
                )}

                {secondPageNews.length > 0 && (
                    <SwiperSlide>
                        <NewsFive news={secondPageNews}/>
                    </SwiperSlide>
                )}

                {lastPageNews.length > 0 && (
                    <SwiperSlide>
                        <NewsFive news={lastPageNews}/>
                    </SwiperSlide>
                )}

            </Swiper>

            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "-35px 0 15px"
            }}>
                <div className="swiper-pagination-container">
                    <div className="swiper-pagination" id={id + '-big-swiper'} style={{textAlign: "left"}}></div>
                </div>

            </div>

            <div className={news.length > 5 ? "navigation-btns-p" : "hide"}>
                <button className="swiper-button-prev-news" style={{marginRight: "16px"}}><ArrowBackIosIcon
                    sx={{marginLeft: "8px"}}/></button>
                <button className="swiper-button-next-news"><ArrowForwardIosIcon/></button>
            </div>

        </div>
    );
};

export default BigSlider;