import newsSlice from "../slices/newsSlice";


export const {
    fetchNewsCategoryRequest,
    fetchNewsCategoryFailure,
    fetchNewsCategorySuccess,
    setDate,
    resetDate,
    setCatId
} = newsSlice.actions