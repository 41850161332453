import React, {useEffect, useState} from 'react';
import {Container, Grid, MenuItem, Pagination, Select, useMediaQuery} from "@mui/material";
import Calendar from "../../components/Calendar/Calendar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axiosApi from "../../axiosApi";
import Preloader from "../../components/Preloader/Preloader";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {fetchGoalsRequest} from "../../store/actions/goalsActions";
import NotFound from "../../components/NotFound/NotFound";
import {resetDate} from "../../store/actions/newsActions";
import {useMemo} from "react";
import {Link} from "react-router-dom";
import LibrarySingleCard from "../../components/LibrarySingleCard/LibrarySingleCard";

const Library = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const [tabId, setTabId] = useState('research');
    const mobileScreen = useMediaQuery('(max-width:504px)');

    const [loading, setLoading] = useState(false);
    const [libraries, setLibraries] = useState([]);
    const [error, setError] = useState(null);
    const [pageCount, setPageCount] = useState(0)
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);

    const date = useSelector(state => state.news.date);

    console.log(libraries)

    const getData = async (page) => {
        setCurrentPage(page)
        if (date) {
            try {
                const selectedDate = new Date(date);
                const day = selectedDate.getDate();
                const month = selectedDate.getMonth() + 1;
                const year = selectedDate.getFullYear();
                const result = await axiosApi.get('/library/'+tabId+'/?page=' + page + '&date=' + year + '-' + month + '-' + day);
                setLibraries(result.data && result.data.results);
                setLoading(false);

            } catch (error) {
                setError(error);
                setLoading(false);
            }
        } else {
            try {
                const result = await axiosApi.get('/library/'+tabId+'/?page=' + page);
                setLibraries(result.data && result.data.results);
                setLoading(false);
            } catch (e) {
                setError(e);
                setLoading(false);
            }
        }
    }


    useEffect(() => {

        const getDate = async () => {
            setLoading(true);
            try {
                const result = await axiosApi.get('/library/'+tabId+'/');
                setLibraries(result.data && result.data.results);
                setPageCount(Math.ceil(result.data.count / 9))
                setCount(result.data.count)
                setLoading(false);
            } catch (e) {
                console.log(e)
            }
        }

        getDate();

        return () => {
            dispatch(resetDate());
        }

    }, [])


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            if (date) {
                const selectedDate = new Date(date);
                const year = selectedDate.getFullYear();
                const month = selectedDate.getMonth() + 1;
                const day = selectedDate.getDay();
                try {
                    const result = await axiosApi.get('/library/'+tabId+'/?page=1&date=' + year + '-' + month + '-' + day);
                    setLibraries(result.data && result.data.results);
                    setPageCount(Math.ceil(result.data.count / 9))
                    setCount(result.data.count)
                    setLoading(false);

                } catch (error) {
                    setError(error);
                    setLoading(false);
                }
            } else {
                try {
                    const result = await axiosApi.get('/library/'+tabId+'/?page=1');

                    setLibraries(result.data && result.data.results);
                    setPageCount(Math.ceil(result.data.count / 9))
                    setCount(result.data.count)
                    setLoading(false);

                } catch (error) {
                    setError(error);
                    setLoading(false);
                }
            }
        }

        fetchData();

    }, [date, tabId]);




    const breadcrumbs = useMemo(() => {
        return (
            <p className="path-text">
                <Link  to={'/'}>{t('main')}</Link> /
                <Link to={'/library'}> {t('library')}</Link> /

                {tabId === 'research' && (
                    <span> {t('research')} </span>
                )}
                {tabId === 'other' && (
                    <span> {t('other')} </span>
                )}

            </p>
        )

    }, [i18n.language, tabId])


    return (
        <Container maxWidth={false} sx={{maxWidth: "1368px"}}>
            <Grid container justifyContent={"space-between"} alignItems={"center"}
                  sx={{marginTop: mobileScreen ? "20px" : "52px"}}>
                <Grid item>
                    <h4 className="block-title">{t('library')}</h4>
                </Grid>
                {/*<Grid item>*/}
                {/*    <button className={"show-more-btn"}>Показать все</button>*/}
                {/*</Grid>*/}

            </Grid>
            <div className="blue-line lib-line"></div>
            <div className={"line"}></div>

            {breadcrumbs}

            <Grid container className={"tabs-parent"}>
                <Grid item xs={12} sm={6} md={6} sx={{borderBottom: "2px solid #28388E",}}>
                    <div
                        className={`tabs-filter ${tabId === 'research' && 'active-tab'}`}
                        id={'research'}
                        onClick={(e) => setTabId(e.target.id)}
                        style={{textTransform: "uppercase"}}
                    >
                        {t('research')}
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{borderBottom: "2px solid #28388E",}}>
                    <div
                        className={`tabs-filter ${tabId === 'other' && 'active-tab'}`}
                        id={'other'}
                        onClick={(e) => setTabId(e.target.id)}
                        style={{textTransform: "uppercase"}}
                    >
                        {t('other')}
                    </div>
                </Grid>
            </Grid>
            <div style={{marginBottom: "50px"}}></div>

                <>{loading ? (
                    <Preloader/>
                ) : (
                    <>

                        {libraries.length === 0 ? (
                            <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                                <Grid item md={3.2}>
                                    <Calendar/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <NotFound/>
                                </Grid>
                            </Grid>


                        ) : (
                            <>
                                <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}
                                      alignItems={"center"}>
                                    <Grid item xs={12} sm={5.8} md={3.2}>
                                        <Calendar/>
                                    </Grid>

                                    {libraries.map(lib => (
                                        <Grid item xs={12} sm={5.8} md={3.3} key={lib.id}>
                                            <LibrarySingleCard content={lib}/>
                                        </Grid>
                                    ))}


                                </Grid>
                                {count>8 && (
                                    <Grid container justifyContent={"right"} sx={{margin: '20px 0'}}>

                                        <Pagination
                                            count={pageCount}
                                            onChange={(event, page) => getData(page)}
                                            page={currentPage}
                                            sx={{
                                                '& .MuiPaginationItem-root': {
                                                    fontWeight: "600",
                                                    backgroundColor: "#F2F6F9",
                                                    borderRadius: "0",
                                                    color: "#55606E",
                                                    height: "38px"
                                                },
                                                '& .Mui-selected': {
                                                    border: ' 1px solid #00A4DC',
                                                    backgroundColor: "transparent",
                                                    color: "#00A4DC",
                                                    borderRadius: "0",
                                                    padding: "5px 0"
                                                },
                                                '& .MuiPaginationItem-root:hover': {
                                                    border: ' 1px solid #00A4DC',
                                                    color: "#00A4DC",
                                                },
                                                '.MuiPagination-ul': {
                                                    '& .MuiPaginationItem-icon': {
                                                        color: '#00A4DC',
                                                    },
                                                    '& .MuiPaginationItem-icon:hover': {
                                                        color: '#00A4DC',
                                                    },
                                                },
                                            }}
                                        />
                                    </Grid>
                                )}

                            </>

                        )}


                    </>
                )}
                </>


        </Container>
    );
};

export default Library;