import React from 'react';
import './styles.css';
import {Grid} from "@mui/material";
import NewsSingleCard from "../NewsSingleCard/NewsSingleCard";

const NewsFive = ({news}) => {
    return (
       <>
           <Grid container justifyContent={"space-between"} sx={{margin:"32px 0"}}>
               <Grid item xs={12}  sm={5.8} md={3.2}>

                   {news[0] && (
                       <NewsSingleCard
                           news={news[0]}
                       />
                   )}

               </Grid>

               <Grid item xs={12} sm={5.8} md={5}>
                   {news[1] && (
                       <NewsSingleCard
                           news={news[1]}
                       />
                   )}
               </Grid>

               <Grid item xs={12} sm={12} md={3.2}>
                   {news[2] && (
                       <NewsSingleCard
                           news={news[2]}
                       />
                   )}
               </Grid>
               <Grid item xs={12} sm={5.8} md={8.5}>
                   {news[3] && (
                       <NewsSingleCard
                           news={news[3]}
                       />
                   )}
               </Grid>
               <Grid item xs={12} sm={5.8} md={3.2}>
                   {news[4] && (
                       <NewsSingleCard
                           news={news[4]}
                       />
                   )}
               </Grid>

           </Grid>

       </>
    );
};

export default NewsFive;