import React, {useState} from 'react';
import ReactPlayer from "react-player";
import './styles.css'
import ModalComponent from "../ModalComponent/ModalComponent";
import {mediaUrl} from "../../config";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import i18n from "i18next";

const SinglePlayer = ({video}) => {

    const [show, setShow] = useState(false);

    return (
        <>
            <div className="player-wrapper">

                <div style={{backgroundImage: `url(${mediaUrl + video.img})`}} className={"img-preview"}>
                    <div onClick={() => setShow(true)} style={{cursor:"pointer"}}>
                        <PlayCircleOutlineIcon sx={{color: "white", fontSize: "70px"}}/>
                    </div>

                </div>

                {i18n.language === 'ky' && (
                    <h4 className="player-title">{video.title_ky}</h4>
                )}

                {i18n.language === 'ru' && (
                    <h4 className="player-title">{video.title_ru}</h4>
                )}

                {i18n.language === 'en' && (
                    <h4 className="player-title">{video.title_en}</h4>
                )}
            </div>

            {show && (
                <ModalComponent onClose={() => setShow(false)} open={show} children={(
                    <ReactPlayer
                        url={video.l}
                        className="react-player"
                        width={"100%"}
                        height={"90%"}
                        controls={true}
                        playing={true}
                    />
                )}/>
            )}
        </>

    );
};

export default SinglePlayer;