import {createSlice} from "@reduxjs/toolkit";

const name = 'goals';

const initialState = {
    goals: [],
    mainSearch:{},
    fetchLoading: false,
    searchLoading: false,
}

const goalsSlice = createSlice({
    name,
    initialState,
    reducers: {
        fetchGoalsRequest(state) {
            state.fetchLoading = true;
        },
        fetchGoalsSuccess(state, action) {
            state.goals = action.payload.sort((a, b) => a.goal_number - b.goal_number);
            state.fetchLoading = false;
        },
        fetchGoalsFailure(state) {
            state.fetchLoading = false;
        },
        getSearchRequest(state){
            state.searchLoading = true;
        },
        getSearchSuccess(state, action){
            state.mainSearch = action.payload;
            state.searchLoading = false;
        },
        getSearchFailure(state){
            state.searchLoading = false;
        },
        clearSearch(state){
            state.mainSearch = {};
        }
    }
});

export default goalsSlice;