import React, {useEffect, useState} from 'react';
import {Container, Grid, MenuItem, Pagination, Select, useMediaQuery} from "@mui/material";
import Calendar from "../../components/Calendar/Calendar";
import SinglePlayer from "../../components/SinglePlayer/SInglePlayer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {fetchGoalsRequest} from "../../store/actions/goalsActions";
import axiosApi from "../../axiosApi";
import NotFound from "../../components/NotFound/NotFound";
import Preloader from "../../components/Preloader/Preloader";
import {resetDate} from "../../store/actions/newsActions";
import {useMemo} from "react";
import {Link} from "react-router-dom";

const Videos = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const mobileScreen = useMediaQuery('(max-width:504px)');

    const date = useSelector(state => state.news.date);


    const [loading, setLoading] = useState(false);
    const [videos, setVideos] = useState([]);
    const [error, setError] = useState(null);
    const [pageCount, setPageCount] = useState(0)
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {

        return () => {
            dispatch(resetDate());
        }

    }, []);


    const getData = async (page) => {
        setCurrentPage(page)
        if (date) {
            try {
                const selectedDate = new Date(date);
                const day = selectedDate.getDate();
                const month = selectedDate.getMonth() + 1;
                const year = selectedDate.getFullYear();
                const result = await axiosApi.get('/videos/videos-info/?page=' + page +  '&date=' + year + '-' + month + '-' + day);
                setVideos(result.data && result.data.results);
                setPageCount(Math.ceil(result.data.count / 8))
                setCount(result.data.count)
                setLoading(false);

            } catch (error) {
                setError(error);
                setLoading(false);
            }
        } else {
            try {
                const result = await axiosApi.get('/videos/videos-info/?page=' + page);
                setVideos(result.data && result.data.results);
                setPageCount(Math.ceil(result.data.count / 8))
                setCount(result.data.count)
                setLoading(false);
            } catch (e) {
                setError(e);
                setLoading(false);
            }
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            if (date) {
                const selectedDate = new Date(date);
                const year = selectedDate.getFullYear();
                const month = selectedDate.getMonth() + 1;
                const day = selectedDate.getDate();

                console.log(year+'-'+month+'-'+day);
                try {
                    const result = await axiosApi.get('/videos/videos-info/?page=1&date=' + year + '-' + month + '-' + day);
                    setVideos(result.data && result.data.results);
                    setPageCount(Math.ceil(result.data.count / 8))
                    setCount(result.data.count)
                    setLoading(false);

                } catch (error) {
                    setError(error);
                    setLoading(false);
                }
            } else {
                try {
                    const result = await axiosApi.get('/videos/videos-info/?page=1');

                    setVideos(result.data && result.data.results);
                    setPageCount(Math.ceil(result.data.count / 8))
                    setCount(result.data.count)
                    setLoading(false);

                } catch (error) {
                    setError(error);
                    setLoading(false);
                }
            }
        }

        fetchData();

    }, [date]);




    const breadcrumbs = useMemo(() => {
        return (
            <p className="path-text">
                <Link  to={'/'}>{t('main')}</Link> /
                <Link to={'/videos'}> {t('videos')}</Link> /

            </p>
        )

    }, [i18n.language])



    return (
        <Container maxWidth={false} sx={{maxWidth: "1368px"}}>
            <Grid container justifyContent={"space-between"} alignItems={"center"}
                  sx={{marginTop: mobileScreen ? "20px" : "52px"}}>
                <Grid item>
                    <h4 className="block-title">{t('videos')}</h4>
                </Grid>
                {/*<Grid item>*/}
                {/*    <button className={"show-more-btn"}>Показать все</button>*/}
                {/*</Grid>*/}

            </Grid>
            <div className="blue-line lib-line"></div>
            <div className={"line"}></div>

            {breadcrumbs}


            {loading ? (
                <Preloader/>
            ) : (
                <>
                    {videos.length === 0 ? (
                        <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                            <Grid item md={3.2}>
                                <Calendar/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <NotFound/>
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            <Grid container justifyContent={"space-between"} sx={{margin: "20px 0 25px"}}
                                  alignItems={"center"}>
                                <Grid item xs={12} sm={5.8} md={3.85}>
                                    <Calendar/>
                                </Grid>

                                <Grid item xs={12} sm={5.8} md={3.85}>
                                    {videos[0] && (
                                        <SinglePlayer video={videos[0]}/>
                                    )}

                                </Grid>
                                <Grid item xs={12} sm={12} md={3.85}>
                                    {videos[1] && (
                                        <SinglePlayer video={videos[1]}/>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid container justifyContent={"space-between"} sx={{margin: "0 0 5px"}}>
                                <Grid item xs={12} sm={5.8} md={3.85}>
                                    {videos[2] && (
                                        <SinglePlayer video={videos[2]}/>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={5.8} md={3.85}>
                                    {videos[3] && (
                                        <SinglePlayer video={videos[3]}/>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={3.85}>
                                    {videos[4] && (
                                        <SinglePlayer video={videos[4]}/>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid container justifyContent={"space-between"} sx={{margin: "0 0 5px"}}>
                                <Grid item xs={12} sm={5.8} md={3.85}>
                                    {videos[5] && (
                                        <SinglePlayer video={videos[5]}/>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={5.8} md={3.85}>
                                    {videos[6] && (
                                        <SinglePlayer video={videos[6]}/>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={3.85}>
                                    {videos[7] && (
                                        <SinglePlayer video={videos[7]}/>
                                    )}
                                </Grid>
                            </Grid>
                        </>

                    )}

                    {count > 8 && (
                        <Grid container justifyContent={"right"} sx={{margin: '20px 0'}}>

                            <Pagination
                                count={pageCount}
                                onChange={(event, page) => getData(page)}
                                page={currentPage}
                                sx={{
                                    '& .MuiPaginationItem-root': {
                                        fontWeight: "600",
                                        backgroundColor: "#F2F6F9",
                                        borderRadius: "0",
                                        color: "#55606E",
                                        height: "38px"
                                    },
                                    '& .Mui-selected': {
                                        border: ' 1px solid #28388E',
                                        backgroundColor: "transparent",
                                        color: "#28388E",
                                        borderRadius: "0",
                                        padding: "5px 0"
                                    },
                                    '& .MuiPaginationItem-root:hover': {
                                        border: ' 1px solid #28388E',
                                        color: "#28388E",
                                    },
                                    '.MuiPagination-ul': {
                                        '& .MuiPaginationItem-icon': {
                                            color: '#28388E',
                                        },
                                        '& .MuiPaginationItem-icon:hover': {
                                            color: '#28388E',
                                        },
                                    },
                                }}
                            />
                        </Grid>
                    )}
                </>

            )}

        </Container>
    );
};

export default Videos;