import React, {useEffect, useMemo, useState} from 'react';
import {Container, Grid, MenuItem, Pagination, Select, useMediaQuery} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {resources} from "../../paths";
import {fetchGoalsRequest} from "../../store/actions/goalsActions";
import {useDispatch, useSelector} from "react-redux";
import axiosApi from "../../axiosApi";
import Preloader from "../../components/Preloader/Preloader";
import {mediaUrl} from "../../config";
import './styles.css';
import NotFound from "../../components/NotFound/NotFound";

const Resources = () => {
    const {t, i18n} = useTranslation();
    const [tabId, setTabId] = useState('local');
    const mobileScreen = useMediaQuery('(max-width:504px)');

    const [loading, setLoading] = useState(false);
    const [resources, setResources] = useState([]);

    const [pageCount, setPageCount] = useState(0)
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);


    const onClose = () => {
        setTimeout(() => {
            document.activeElement.blur();
        }, 0)
    }


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (tabId === 'local') {

                    const result = await axiosApi.get('/resources/government-agencies/?page=1');
                    setLoading(false)
                    setResources(result.data && result.data.results);
                    setPageCount(Math.ceil(result.data.count / 7))
                    setCount(result.data.count);

                } else if (tabId === 'international') {

                    const result = await axiosApi.get('/resources/international-agencies/?page=1');
                    setLoading(false)
                    setResources(result.data && result.data.results);
                    setPageCount(Math.ceil(result.data.count / 7))
                    setCount(result.data.count);

                } else if (tabId === 'otherResources') {

                    const result = await axiosApi.get('/resources/other-agencies/?page=1');
                    setLoading(false)
                    setResources(result.data && result.data.results);
                    setPageCount(Math.ceil(result.data.count / 7))
                    setCount(result.data.count);
                }



            } catch (err) {
                console.log(err);
                setLoading(false)
            }
        };
        fetchData();

    }, [tabId]);



    const getData = async (page) => {
        setCurrentPage(page)
        if (tabId === 'local') {
            try {
                const result = await axiosApi.get('/resources/government-agencies/?page=' + page);
                setLoading(false)
                setResources(result.data && result.data.results);
                setPageCount(Math.ceil(result.data.count / 7))
                setCount(result.data.count);
            } catch (e) {
                console.log(e);
                setLoading(false)
            }
        } else if (tabId === 'international') {
            try {
                const result = await axiosApi.get('/resources/international-agencies/?page=' + page);
                setLoading(false)
                setResources(result.data && result.data.results);
                setPageCount(Math.ceil(result.data.count / 7))
                setCount(result.data.count);
            } catch (e) {
                console.log(e);
                setLoading(false)
            }
        }else if (tabId === 'otherResources') {
            try {
                const result = await axiosApi.get('/resources/other-agencies/?page=' + page );
                setLoading(false)
                setResources(result.data && result.data.results);
                setPageCount(Math.ceil(result.data.count / 7))
                setCount(result.data.count);
            } catch (e) {
                console.log(e);
                setLoading(false)
            }
        }
    }


    const breadcrumbs = useMemo(() => {
        return (
            <p className="path-text">
                <Link to={'/'}>{t('main')}</Link> /
                <Link to={resources}> {t('resources')}</Link> /
                {tabId === 'local' && (
                    <span> {t('government')}</span>
                )}

                {tabId === 'international' && (
                    <span> {t('international')}</span>
                )}

                {tabId === 'otherResources' && (
                    <span> {t('otherResources')}</span>
                )}

            </p>
        )

    }, [i18n.language, tabId])


    return (
        <Container maxWidth={false} sx={{maxWidth: "1368px"}}>
            <Grid container justifyContent={"space-between"} alignItems={"center"}
                  sx={{marginTop: mobileScreen ? "20px" : "52px"}}>
                <Grid item>
                    <h4 className="block-title">{t('resources')}</h4>
                </Grid>
                {/*<Grid item>*/}
                {/*    <button className={"show-more-btn"}>Показать все</button>*/}
                {/*</Grid>*/}

            </Grid>
            <div className="blue-line lib-line"></div>
            <div className={"line"}></div>

            {breadcrumbs}

            <Grid container className={"tabs-parent"}>
                <Grid item xs={12} sm={6} md={4} sx={{borderBottom: "2px solid #28388E",}}>
                    <div
                        className={`tabs-filter ${tabId === 'local' && 'active-tab'}`}
                        id={'local'}
                        onClick={(e) => setTabId(e.target.id)}
                    >
                        {t('government')}
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} sx={{borderBottom: "2px solid #28388E",}}>
                    <div
                        className={`tabs-filter ${tabId === 'international' && 'active-tab'}`}
                        id={"international"}
                        onClick={(e) => setTabId(e.target.id)}
                    >
                        {t('international')}
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} sx={{borderBottom: "2px solid #28388E",}}>
                    <div
                        className={`tabs-filter ${tabId === 'otherResources' && 'active-tab'}`}
                        id={'otherResources'}
                        onClick={(e) => setTabId(e.target.id)}
                    >
                        {t('otherResources')}
                    </div>
                </Grid>
            </Grid>
            <div style={{marginBottom: "50px"}}></div>


            {loading ? (
                <Preloader/>
            ) : (
                <Grid>

                    {resources.length === 0 ? (
                        <div style={{height:"50vh", display:"flex", alignItems:"flex-start", justifyContent:"center"}}>
                            <NotFound/>
                        </div>
                    ):(
                        <>
                            {resources.length > 0 && resources.map(res => (
                                <Grid container className={"resource-card"} justifyContent={"space-between"}
                                      alignItems={'center'}>
                                    <Grid item xs={12} sm={2} md={2} container
                                          justifyContent={{xs: "center", sm: "space-between"}}
                                          sx={{marginBottom: {xs: "20px", sm: "0"}}}>
                                        <img src={mediaUrl + res.icon} alt="emblem" className='emblem'/>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={5.5} container
                                          justifyContent={{xs: "center", sm: "space-between"}}
                                          sx={{marginBottom: {xs: "20px", sm: "0"}}} flexDirection={'column'}>

                                        {i18n.language === 'ky' && (
                                            <p className={'resource-title'}>{res.title_ky}</p>
                                        )}
                                        {i18n.language === 'ru' && (
                                            <p className={'resource-title'}>{res.title_ru}</p>
                                        )}

                                        {i18n.language === 'en' && (
                                            <p className={'resource-title'}>{res.title_en}</p>
                                        )}


                                        {/*<div className={"resource-card-inner"}>*/}
                                        {/*    {res.goal_ids.length > 0 && res.goal_ids.map(goal => (*/}
                                        {/*        <img src={mediaUrl + goal.little_icon} alt="goal" key={goal.id}/>*/}
                                        {/*    ))}*/}
                                        {/*</div>*/}
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4.5} className="resource-contact" container
                                          justifyContent={{xs: "center", sm: "right"}}
                                          sx={{marginBottom: {xs: "20px", sm: "0"}}} flexDirection={'column'}>
                                        <a href={res.link} target="_blank" rel="noopener noreferrer">{res.link}</a>

                                        {i18n.language === 'ky' && (
                                            <p>{res.address_ky}</p>
                                        )}
                                        {i18n.language === 'ru' && (
                                            <p>{res.address_ru}</p>
                                        )}
                                        {i18n.language === 'en' && (
                                            <p>{res.address_en}</p>
                                        )}
                                        <p>{t('phone')}: {res.phone}</p>
                                        <p>{t('email')}: {res.email}</p>
                                    </Grid>
                                </Grid>
                            ))}
                        </>
                    ) }

                </Grid>
            )}


            {count > 7 && (
                <Grid container justifyContent={"right"} sx={{margin: '20px 0'}}>

                    <Pagination
                        count={pageCount}
                        onChange={(event, page) => getData(page)}
                        page={currentPage}
                        sx={{
                            '& .MuiPaginationItem-root': {
                                fontWeight: "600",
                                backgroundColor: "#F2F6F9",
                                borderRadius: "0",
                                color: "#55606E",
                                height: "38px"
                            },
                            '& .Mui-selected': {
                                border: ' 1px solid #00A4DC',
                                backgroundColor: "transparent",
                                color: "#00A4DC",
                                borderRadius: "0",
                                padding: "5px 0"
                            },
                            '& .MuiPaginationItem-root:hover': {
                                border: ' 1px solid #00A4DC',
                                color: "#00A4DC",
                            },
                            '.MuiPagination-ul': {
                                '& .MuiPaginationItem-icon': {
                                    color: '#00A4DC',
                                },
                                '& .MuiPaginationItem-icon:hover': {
                                    color: '#00A4DC',
                                },
                            },
                        }}
                    />
                </Grid>
            )}

        </Container>
    );
};

export default Resources;