import React, {useEffect, useMemo, useState} from 'react';
import {Container, Grid, MenuItem, Pagination, Select, useMediaQuery} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import './styles.css';
import Calendar from "../../components/Calendar/Calendar";
import NewsSingleCard from "../../components/NewsSingleCard/NewsSingleCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {useDispatch, useSelector} from "react-redux";
import {fetchNewsCategoryRequest, resetDate, setCatId} from "../../store/actions/newsActions";
import {useTranslation} from "react-i18next";
import {fetchGoalsRequest} from "../../store/actions/goalsActions";
import axiosApi from "../../axiosApi";
import NotFound from "../../components/NotFound/NotFound";
import Preloader from "../../components/Preloader/Preloader";
import {Link} from "react-router-dom";

const News = () => {
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const category = useSelector(state => state.news.category);
    const [aim, setAim] = useState(1);
    const mobileScreen = useMediaQuery('(max-width:504px)');
    const goals = useSelector(state => state.goals.goals);
    const catId = useSelector(state => state.news.tabId);

    const [tabId, setTabId] = useState(catId ? catId : 1);
    const [aimId, setAimId] = useState(1);
    const date = useSelector(state => state.news.date);
    // const selectedDate = date && new Date(date);
    // const day = selectedDate.getDate();
    // const month = selectedDate.getMonth() + 1;
    // const year = selectedDate.getFullYear();


    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [pageCount, setPageCount] = useState(0)
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);


    // console.log(news)

    useEffect(() => {
        dispatch(fetchNewsCategoryRequest());
        dispatch(fetchGoalsRequest());


    }, [])


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            if (date) {
                try {
                    const selectedDate = date ? new Date(date) : new Date();
                    const day = selectedDate.getDate();
                    const month = selectedDate.getMonth() + 1;
                    const year = selectedDate.getFullYear();
                    const result = await axiosApi.get('/news/news-info/?page=' + 1 + '&date=' + year + '-' + month + '-' + day);
                    setNews(result.data && result.data.results);
                    setNews(result.data.results)
                    setPageCount(Math.ceil(result.data.count / 7))
                    setCount(result.data.count)
                    setLoading(false);

                } catch (error) {
                    setError(error);
                    setLoading(false);
                }

            } else {
                try {

                    const result = await axiosApi.get('/news/news-info/?page=' + 1);
                    setNews(result.data && result.data.results);
                    setNews(result.data.results)
                    setPageCount(Math.ceil(result.data.count / 7))
                    setCount(result.data.count)
                    setLoading(false);

                } catch (error) {
                    setError(error);
                    setLoading(false);
                }
            }

        }

        fetchData();


    }, [date])



    const getData = async (page) => {
        setCurrentPage(page)

        if (date) {
            try {
                const selectedDate = date ? new Date(date) : new Date();
                const day = selectedDate.getDate();
                const month = selectedDate.getMonth() + 1;
                const year = selectedDate.getFullYear();
                const result = await axiosApi.get('/news/news-info/?page=' + 1 + '&date=' + year + '-' + month + '-' + day);
                setNews(result.data && result.data.results);
                setLoading(false);
                setPageCount(Math.ceil(result.data.count / 7))
                setCount(result.data.count)

            } catch (error) {
                setError(error);
                setLoading(false);
            }
        } else {
            try {
                const result = await axiosApi.get('/news/news-info/?page=' + 1);
                setNews(result.data && result.data.results);
                setLoading(false);
                setPageCount(Math.ceil(result.data.count / 7))
                setCount(result.data.count)

            } catch (e) {
                setError(e);
                setLoading(false);
            }
        }


    }



    const breadcrumbs = useMemo(() => {
        return (
            <p className="path-text">
                <Link  to={'/'}>{t('main')}</Link> /
                <Link to={'/news'}> {t('news')}</Link>

            </p>
        )

    }, [i18n.language])


    return (
        <Container maxWidth={false} sx={{maxWidth: "1368px"}}>
            <Grid container justifyContent={"space-between"} alignItems={"center"}
                  sx={{marginTop: mobileScreen ? "20px" : "52px"}}>
                <Grid item>
                    <h4 className="block-title">{t('news')}</h4>
                </Grid>
                {/*<Grid item>*/}
                {/*    <button className={"show-more-btn"}>Показать все</button>*/}
                {/*</Grid>*/}

            </Grid>
            <div className="blue-line lib-line"></div>
            <div className={"line"}></div>

            {breadcrumbs}

            {loading ? (
                <Preloader/>
            ) : (
                <>
                    {news.length === 0 ? (
                        <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                            <Grid item md={3.2}>
                                <Calendar/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <NotFound/>
                            </Grid>
                        </Grid>
                    ) : (
                        <>

                            <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                                <Grid item xs={12} md={3.2}>
                                    <Calendar/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={7.5} lg={5}>
                                    {news[0] && (
                                        <NewsSingleCard
                                            news={news[0]}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} md={12} lg={3.2}>
                                    {news[1] && (
                                        <NewsSingleCard
                                            news={news[1]}
                                        />
                                    )}
                                </Grid>
                                <Grid item sx={12} md={8.5}>
                                    {news[2] && (
                                        <NewsSingleCard
                                            news={news[2]}
                                        />
                                    )}
                                </Grid>
                                <Grid item md={3.2}>
                                    {news[3] && (
                                        <NewsSingleCard
                                            news={news[3]}
                                        />
                                    )}
                                </Grid>
                                <Grid item md={3.2}>
                                    {news[4] && (
                                        <NewsSingleCard
                                            news={news[4]}
                                        />
                                    )}
                                </Grid>
                                <Grid item md={5}>
                                    {news[5] && (
                                        <NewsSingleCard
                                            news={news[5]}
                                        />
                                    )}

                                </Grid>
                                <Grid item md={3.2}>
                                    {news[6] && (
                                        <NewsSingleCard
                                            news={news[6]}
                                        />
                                    )}
                                </Grid>
                                <Grid item md={8.5}>
                                    {news[7] && (
                                        <NewsSingleCard
                                            news={news[7]}
                                        />
                                    )}
                                </Grid>
                                <Grid item md={3.2}>
                                    {news[8] && (
                                        <NewsSingleCard
                                            news={news[8]}
                                        />
                                    )}
                                </Grid>
                            </Grid>


                            {count > 7 && (
                                <Grid container justifyContent={"right"} sx={{margin: '20px 0'}}>

                                    <Pagination
                                        count={pageCount}
                                        onChange={(event, page) => getData(page)}
                                        page={currentPage}
                                        sx={{
                                            '& .MuiPaginationItem-root': {
                                                fontWeight: "600",
                                                backgroundColor: "#F2F6F9",
                                                borderRadius: "0",
                                                color: "#55606E",
                                                height: "38px"
                                            },
                                            '& .Mui-selected': {
                                                border: ' 1px solid #28388E',
                                                backgroundColor: "transparent",
                                                color: "#28388E",
                                                borderRadius: "0",
                                                padding: "5px 0"
                                            },
                                            '& .MuiPaginationItem-root:hover': {
                                                border: ' 1px solid #28388E',
                                                color: "#28388E",
                                            },
                                            '.MuiPagination-ul': {
                                                '& .MuiPaginationItem-icon': {
                                                    color: '#28388E',
                                                },
                                                '& .MuiPaginationItem-icon:hover': {
                                                    color: '#28388E',
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                            )}


                        </>
                    )}
                </>
            )}


        </Container>
    );
};

export default News;