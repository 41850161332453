import React, {useState} from 'react';
import './styles.css';
import lib1 from '../../assets/images/lib.jpg'
import download from '../../assets/images/Download.svg'
import shareIcon from "../../assets/images/Base.svg";
import shareArrow from "../../assets/images/Arrow.svg";
import downloadIcon from '../../assets/images/ArrowDown.svg';
import downloadIc from '../../assets/images/BaseDownload.svg'
import {Grid} from "@mui/material";
import ModalComponent from "../ModalComponent/ModalComponent";
import ShareComponent from "../ShareComponent/ShareComponent";
import {mediaUrl} from "../../config";
import {useTranslation} from "react-i18next";
import axios from "axios";
import fileDownload from "js-file-download";
import {ClockLoader} from "react-spinners";


const LibrarySingleCard = ({content}) => {
    const {t, i18n} = useTranslation();
    const [shareShow, setShareShow] = useState(false);

    const [loading, setLoading] = useState(false);

    const handleDownload = async (url) => {
        setLoading(true);
        try{
            const arr = url.split("/");
            const filename = arr[arr.length-1];
          await axios.get(url, {
                responseType: 'blob',
            })
                .then((res) => {
                    fileDownload(res.data, filename)
                })
            setLoading(false);
        } catch(e) {
            console.log(e);
            setLoading(false);
        }
    }


    return (
        <>{content && (
            <div className="lib-card">
                <div className={'lib-wrapper'}
                     style={{
                    backgroundImage: `url(${mediaUrl+content.cover})`,
                    backgroundSize: 'cover',
                         backgroundPosition:"0 -10px ",
                }}>
                    {/*<img src={mediaUrl + content.cover} alt="doc"/>*/}
                </div>
                <div className="title-wrapper">
                    {i18n.language === "ky" && (
                        <h5>{content.title_ky}</h5>
                    )}

                    {i18n.language === "ru" && (
                        <h5>{content.title_ru}</h5>
                    )}

                    {i18n.language === "en" && (
                        <h5>{content.title_en}</h5>
                    )}
                </div>

                <p>PDF ({(parseInt(content.file_size) / 1048576).toFixed(2)} MB)</p>

                <div className="share-btn-wrapper">
                    <Grid container justifyContent={"space-between"}>
                        <Grid item xs={12} md={5.5} style={{marginBottom:"10px"}}>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "left",}}
                                 className={"download-btn-div"}>
                                <button className={"download-btn"} onClick={()=>handleDownload(mediaUrl+content.file)}>
                                    {t('justDownload')}

                                </button>

                                {loading ? (
                                    <div style={{
                                        position: "relative",
                                        display: "flex",
                                        alignItems: "end",
                                        margin: "0px 0 0 10px "
                                    }}>
                                        <ClockLoader size={19} color={'#00A4DC'} />
                                    </div>
                                ):(
                                    <div style={{
                                        position: "relative",
                                        display: "flex",
                                        alignItems: "end",
                                        margin: "8px 0 0 10px "
                                    }}>

                                        <img src={downloadIc} alt="shareIcon" className={"down-base"}/>
                                        <img src={downloadIcon} alt="shareIcon" className={"down-arrow"}/>
                                    </div>
                                )}

                            </div>

                        </Grid>
                        <Grid item xs={12} md={5.5}>
                            <div
                                style={{display: "flex", alignItems: "center", justifyContent: "left", marginRight: "50px"}}
                                className={"share-btn-div"}>
                                <button
                                    className="blue-btn"
                                    onClick={() => setShareShow(!shareShow)}
                                >{t('share')}
                                </button>

                                <div style={{position: "relative", display: "flex", alignItems: "end"}}>
                                    <img src={shareIcon} alt="shareIcon" className={"share-icon"}/>
                                    <img src={shareArrow} alt="shareIcon" className={"share-arrow"}/>
                                </div>
                            </div>
                        </Grid>


                        {shareShow && (
                            <div style={{position:"absolute", bottom:"-28px", right:"21px"}} >
                                <ShareComponent onClose={()=>setShareShow(false)} url={mediaUrl+content.file}/>
                            </div>

                        )}
                    </Grid>

                </div>
            </div>
        )}</>

    );
};

export default LibrarySingleCard;