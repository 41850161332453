import {put, takeEvery} from 'redux-saga/effects';
import {
    fetchGoalsFailure,
    fetchGoalsRequest,
    fetchGoalsSuccess,
    getSearchFailure, getSearchRequest,
    getSearchSuccess
} from "../actions/goalsActions";
import axiosApi from "../../axiosApi";



export function* fetchGoalsSaga() {

    try {
        const response = yield axiosApi.get('/goals/info/');
        yield put(fetchGoalsSuccess(response.data));
    } catch (e) {
        yield put(fetchGoalsFailure());
    }
}


export function* getSearchSaga({payload}) {

    try {
        const response = yield axiosApi.get('/main_page/search/?q='+payload);
        yield put(getSearchSuccess(response.data));
    } catch (e) {
        yield put(getSearchFailure());
    }
}



const goalsSaga = [
    takeEvery(fetchGoalsRequest, fetchGoalsSaga),
    takeEvery(getSearchRequest, getSearchSaga),
];

export default goalsSaga;