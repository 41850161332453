import {put, takeEvery} from 'redux-saga/effects';
import axiosApi from "../../axiosApi";
import {fetchNewsCategoryFailure, fetchNewsCategoryRequest, fetchNewsCategorySuccess} from "../actions/newsActions";



export function* fetchNewsCatSaga() {

    try {
        const response = yield axiosApi.get('/news/news-categories/');
        yield put(fetchNewsCategorySuccess(response.data));
    } catch (e) {
        yield put(fetchNewsCategoryFailure());
    }
}



const newsSaga = [
    takeEvery(fetchNewsCategoryRequest, fetchNewsCatSaga),
];

export default newsSaga;