import React, {useEffect, useState} from 'react';
import './styles.css'
import calendarIcon from '../../assets/images/calendar.svg';
import pin from '../../assets/images/pin.svg';
import users from '../../assets/images/users.svg';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useTranslation} from "react-i18next";
import {mediaUrl} from "../../config";
import {Link} from "react-router-dom";


const EventSingleCard = ({content}) => {
    const {t, i18n} = useTranslation();

    const [yearStart, monthStart, dayStart] = content.date_from.split('-').map(Number);

    const [yearEnd, monthEnd, dayEnd] = content.date_to.split('-').map(Number);

    const [sameMonth, setSameMonth] = useState(false)
    const [sameDay, setSameDay] = useState(false)

    useEffect(()=>{
        if(monthStart === monthEnd){
            setSameMonth(true)
        }

        if(monthStart === monthEnd && dayStart === dayEnd){
            setSameDay(true)
        }
    },[content])


    function getMonthName(monthNumber, language = 'en') {
        const months = {
            ru: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        };

        return months[language][monthNumber - 1];
    }

    return (
        <div className='event-card'>
            {i18n.language === 'ky' && (
                <h5 className='event-title'>{content.title_ky}</h5>
            )}
            {i18n.language === 'ru' && (
                <h5 className='event-title'>{content.title_ru}</h5>
            )}
            {i18n.language === 'en' && (
                <h5 className='event-title'>{content.title_en}</h5>
            )}

            <div className={"event-img"}
                 style={{backgroundImage: `url(${ content?.events_slider[0] ? mediaUrl + content?.events_slider[0]?.image : mediaUrl + content?.preview_image })`}}>
            </div>

            <div className={"event-inner"}>
                <img src={calendarIcon} alt="calendarIcon"/>

                {i18n.language === 'ky'  && (
                    <p>
                        <span>{t('date')}: </span>{dayStart} {!sameMonth && getMonthName(monthStart, 'ru')} - {!sameDay && dayEnd} {getMonthName(monthEnd, 'ru')}
                        , {yearStart}</p>
                )}


                {i18n.language === 'ru' && (
                    <p>
                        <span>{t('date')}: </span>{dayStart} {!sameMonth && getMonthName(monthStart, 'ru')} - {!sameDay && dayEnd} {getMonthName(monthEnd, 'ru')}
                        , {yearStart}</p>
                )}

                {i18n.language === 'en' && (
                    <p>
                        <span>{t('date')}: </span>{dayStart} {!sameMonth && getMonthName(monthStart, 'en')} - {!sameDay && dayEnd} {getMonthName(monthEnd, 'en')}
                        , {yearStart}</p>
                )}

            </div>
            <div className={"event-inner"}>
                <img src={pin} alt="pin"/>
                {i18n.language === 'ky' && (
                    <p><span>{t('location')}: </span>{content.place_ky}</p>
                )}
                {i18n.language === 'ru' && (
                    <p><span>{t('location')}: </span>{content.place_ru}</p>
                )}
                {i18n.language === 'en' && (
                    <p><span>{t('location')}: </span>{content.place_en}</p>
                )}

            </div>
            <div className={"event-inner"} style={{marginBottom:"10px"}}>
                <img src={users} alt="users"/>
                <p><span>{t('organizer')}: </span>{content.host}</p>
            </div>

            <div className={"more-btn-div"}>
                <Link to={'/events/' + content.id} className="learn-more">{t('more')} </Link>
                <div className={"more-btn-line blue-more"}></div>
                <ArrowForwardIosIcon sx={{color: "#00A4DC", height: "18px"}}/>
            </div>
        </div>
    );
};

export default EventSingleCard;