import {createSlice} from "@reduxjs/toolkit";

const name = 'news';

const initialState = {
    category: [],
    fetchLoading: false,
    date:"",
    tabId:1,
}

const newsSlice = createSlice({
    name,
    initialState,
    reducers: {
        fetchNewsCategoryRequest(state) {
            state.fetchLoading = true;
        },
        fetchNewsCategorySuccess(state, action) {
            state.category = action.payload;
            state.fetchLoading = false;
        },
        fetchNewsCategoryFailure(state) {
            state.fetchLoading = false;
        },
        setDate(state,action){
            state.date = action.payload
        },
        resetDate(state){
            state.date = ""
        },
        setCatId(state,action){
            state.tabId = action.payload
        },
    }
});

export default newsSlice;