export const main = '/';
export const events = '/events';
export const news = '/news';
export const resources = '/resources';
export const library = '/library';
export const videos = '/videos';
export const about = '/about';







