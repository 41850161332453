import React, {useEffect, useMemo, useState} from 'react';
import './styles.css';
import {Container, Grid, useMediaQuery} from "@mui/material";
import shareIcon from "../../assets/images/Base.svg";
import pdf from '../../assets/images/pdf-icon.svg'
import ShareComponent from "../../components/ShareComponent/ShareComponent";
import axiosApi from "../../axiosApi";
import {useTranslation} from "react-i18next";
import Preloader from "../../components/Preloader/Preloader";
import DOMPurify from "dompurify";
import shareArrow from "../../assets/images/Arrow.svg";
import fileDownload from "js-file-download";
import axios from "axios";
import {mediaUrl} from "../../config";
import {Link} from "react-router-dom";
import {about} from "../../paths";
import MiniSlider from "../../components/MiniSlider/MiniSlider";

const About = () => {
    const {t, i18n} = useTranslation();
    const [shareShow, setShareShow] = useState(false);
    const mobileScreen = useMediaQuery('(max-width:704px)');

    const [commision, setCommision] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const result = await axiosApi.get('/about_us/info/');
                setCommision(result.data);
                setLoading(false)
            } catch (err) {
                setError(err);
                setLoading(false)
            }
        };
        fetchData();

    }, []);

    const handleDownload = (url) => {
        const arr = url.split("/");
        const filename = arr[arr.length - 1];
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
            })

    }

    const breadcrumbs = useMemo(() => {
        return (
            <p className="path-text">
                <Link to={'/'}>{t('main')}</Link> /
                <Link to={about}> {t('about')}</Link> /
            </p>
        )

    }, [i18n.language])


    return (
        <Container maxWidth={false} sx={{maxWidth: "1368px"}}>

            {loading ? (
                <Preloader/>
            ) : (
                <>
                    <Grid container justifyContent={"space-between"} alignItems={"center"}
                          sx={{marginTop: mobileScreen ? "20px" : "52px"}}>

                        <Grid item>
                            <h4 className="block-title">{t('commissionPage')}</h4>

                        </Grid>


                        {/*<Grid item>*/}
                        {/*    <button className={"show-more-btn"}>Показать все</button>*/}
                        {/*</Grid>*/}

                    </Grid>
                    <div className="blue-line lib-line"></div>
                    <div className={"line"}></div>

                    {breadcrumbs}
                    {commision.length > 0 && (<>
                            {i18n.language === 'ky' && (
                                <h4 className="block-title">{commision[0]?.title_ky}</h4>
                            )}
                            {i18n.language === 'ru' && (
                                <h4 className="block-title">{commision[0].title_ru}</h4>
                            )}
                            {i18n.language === 'en' && (
                                <h4 className="block-title">{commision[0].title_en}</h4>
                            )}

                        </>
                    )}
                    <div className="commission-inner">
                        <div className="slider-float-box">
                            {commision.length > 0 && (
                                <MiniSlider id={"about"} pictures={commision[0].about_us_slider}/>
                            )}
                        </div>

                        {commision.length > 0 &&(
                            <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(commision[0][`desc_${i18n.language}`])}}></div>
                        )}

                    </div>

                    <Grid container justifyContent={"space-between"} alignItems={"center"}
                          sx={{marginBottom: "55px", marginTop: "32px"}}>
                        <Grid item className="btns-parent" sx={{marginBottom: "10px"}}>
                            {/*<button*/}
                            {/*    className="blue-btn"*/}
                            {/*    onClick={() => setShareShow(!shareShow)}*/}
                            {/*>Поделиться <img src={shareIcon} alt="shareIcon"/></button>*/}

                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "left",
                                marginRight: "60px",
                                position:"relative"
                            }} className={"share-btn-div"}>
                                <button
                                    className="blue-btn"
                                    onClick={() => setShareShow(!shareShow)}
                                >{t('share')}</button>

                                <div style={{position: "relative", display: "flex", alignItems: "end"}}>
                                    <img src={shareIcon} alt="shareIcon" className={"share-icon"}/> <img
                                    src={shareArrow} alt="shareIcon" className={"share-arrow"}/>
                                </div>

                                {shareShow && (
                                    <div style={{position:"absolute", bottom:"-50px", right:"-92"}} >
                                        <ShareComponent onClose={()=>setShareShow(false)} url={null}/>
                                    </div>

                                )}
                            </div>

                            <button className="blue-btn"
                                    onClick={() => handleDownload(mediaUrl + commision[0].file)}>{t('download')} <img
                                src={pdf} alt="pdf icon"/></button>


                        </Grid>

                    </Grid>


                </>
            )}

        </Container>
    );
};

export default About;