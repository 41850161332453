import React, {useRef, useState} from 'react';
import {Autoplay, Navigation, Pagination, Thumbs} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import './styles.css'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {mediaUrl} from "../../config";

const MiniSlider = ({id, pictures}) => {

    const [activeThumb, setActiveThumb] = useState()
    const swiperRef = useRef();
    const [swiperReady, setSwiperReady] = useState(false);

    const handleSwiperReady = () => {
        setSwiperReady(true);
    };

    const handleNextClick = () => {
        if (swiperReady) {
            swiperRef.current.slideNext();
        }
    };

    const handlePrevClick = () => {
        if (swiperReady) {
            swiperRef.current.slidePrev();
        }
    };
    return (
        <div>
            <div className="mini-swiper">
                <Swiper
                    hashNavigation={{
                        watchState: true,
                    }}
                    pagination={{
                        el: `#${id}-swiper-pagination`,
                        type: 'bullets'
                    }}
                    slidesPerView={1}
                    loop={true}
                    modules={[Autoplay, Navigation, Thumbs, Pagination]}
                    thumbs={{swiper: activeThumb}}
                    // autoplay={{
                    //     delay: 6000,
                    //     disableOnInteraction: false,
                    // }}
                    navigation={{
                        nextEl: '.detail-next',
                        prevEl: '.detail-prev',
                    }}
                    speed={800}
                >

                    {pictures.map(pic=>(
                        <SwiperSlide key={pic.id}>
                            <div>
                                <img
                                    src={mediaUrl+pic.image}
                                    alt="cart-img"
                                />
                            </div>
                        </SwiperSlide>
                    ))}



                </Swiper>
                <div className='detail-prev' onClick={handlePrevClick}>
                    <ArrowBackIosIcon sx={{color: "white", fontSize: "25px"}}/>
                </div>
                <div className="detail-next" onClick={handleNextClick}>
                    <ArrowForwardIosIcon sx={{color: "white", fontSize: "25px"}}/>
                </div>
            </div>
            <div>
                <div className="mini-inner-swiper">
                    <Swiper
                        hashNavigation={{
                            watchState: true,
                        }}
                        loop={true}
                        ref={swiperRef}
                        speed={700}
                        spaceBetween={8}
                        slidesPerView={5}
                        onSwiper={setActiveThumb}
                        className={"my-mini-swiper"}
                        modules={[Navigation, Thumbs,]}
                        breakpoints={{
                            // when window width is >= 320px
                            320: {
                                slidesPerView:2,
                                spaceBetween: 16
                            },
                            // when window width is >= 480px

                            380: {
                                slidesPerView: 2,
                                spaceBetween: 25
                            },
                            400: {
                                slidesPerView: 3,
                                spaceBetween: 15
                            },
                            420: {
                                slidesPerView: 3,
                                spaceBetween: 18
                            },
                            440: {
                                slidesPerView: 3,
                                spaceBetween: 30
                            },
                            480: {
                                slidesPerView: 4,
                                spaceBetween: 2
                            },


                            540: {
                                slidesPerView: 4,
                                spaceBetween: 16
                            },

                            640: {
                                slidesPerView: 5,
                                spaceBetween: 8
                            },
                            1040: {
                                slidesPerView: 5,
                                spaceBetween: 10
                            },
                        }}
                    >

                        {pictures.map(pic=>(
                            <SwiperSlide key={pic.id}>
                                <img
                                    src={mediaUrl+pic.image}
                                    alt="cart-img"
                                />
                            </SwiperSlide>
                        ))}


                    </Swiper>

                    <div className="swiper-mini-container" style={{flex: 1}}>
                        <div className="swiper-pagination"  id={id+'-swiper-pagination'} style={{textAlign: "center"}}></div>
                    </div>

                </div>
            </div>
        </div>

    );
};

export default MiniSlider;