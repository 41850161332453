import React from 'react';
import {CircleLoader} from "react-spinners";


const Preloader = () => {
    return (
        <div style={{width:"100%", height:"90vh", display:"flex", justifyContent:"center", alignItems:"center", caretColor:"transparent"}}>
            <CircleLoader
                color={"#28388E"}
                loading={true}
                size={250}
                aria-label="Loading Spinner"
                data-testid="loader"
                // style={{
                //     borderColor:"red"
                // }}
            />
        </div>
    );
};

export default Preloader;