import React from 'react';
import './styles.css'
import {Box, Button, Container, Grid} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {about, events, library, main, news, resources, videos} from "../../paths";
import fb from '../../assets/images/fb.svg';
import tw from '../../assets/images/tw.svg';
import insta from '../../assets/images/insta.svg'
import linkedin from '../../assets/images/in.svg'
import youtube from '../../assets/images/yb.svg';
import enLogo from '../../assets/images/Logo Eng.svg';
import ruLogo from '../../assets/images/Logo Rus.svg';
import kyLogo from '../../assets/images/Logo Kyr.svg';
import logo from "../../assets/images/SDG_logo.svg";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const navigate = useNavigate();
    let location = useLocation();
    let pathname = location.pathname;
    const {t, i18n} = useTranslation();


    const pages = [
        {name: 'about', route: about},
        {name: 'news', route: news},
        {name: 'events', route: events},
        {name: 'library', route: library},
        {name: 'videos', route: videos},
        {name: 'resources', route: resources},
    ];

    return (
        <div className="footer">
            <Container maxWidth={false} sx={{maxWidth: "1368px"}}>
                <Grid container alignItems={"center"} justifyContent={"space-between"}
                      sx={{marginTop: "10px"}}>
                    <Grid item xs={7} md={4}>
                        <div style={{display: "flex", alignItems: "center", margin:"22px 0"}} onClick={() => navigate(main)}>

                            {i18n.language === 'ky' && (
                                <img src={kyLogo} alt="logo" style={{display: "block", height: "65px"}}/>
                            )}

                            {i18n.language === 'ru' && (
                                <img src={ruLogo} alt="logo" style={{display: "block", height: "65px"}}/>
                            )}

                            {i18n.language === 'en' && (
                                <img src={enLogo} alt="logo" style={{display: "block", height: "65px"}}/>
                            )}
                        </div>

                    </Grid>
                    <Grid item xs={4} md={4} sx={{display: "flex", justifyContent: "right"}}>
                        <img style={{width: "65px", minHeight: "57px", display: "block"}}
                             src={logo} alt="logo"/>
                        <p className={'footer-logo-link'}> {t('goals')} </p>
                    </Grid>
                </Grid>
                <div className='line'></div>
                <Grid container justifyContent={"space-between"} sx={{paddingTop: "15px", marginBottom: "10px"}}>
                    <Grid item lg={7}>
                        <Box sx={{
                            display: {xs: 'none', md: 'flex'},
                            justifyContent: "space-between",
                            flexGrow: "1",
                        }}>


                            {pages.map(page => (
                                <Button
                                    key={page.name}
                                    onClick={() => {
                                        navigate(page.route)
                                    }}
                                    sx={{
                                        my: 2,
                                        display: 'block',
                                        color: 'white',
                                        fontFamily: 'Montserrat , sans-serif',
                                        fontWeight: "500",
                                        fontSize: "14px",
                                        textTransform: "uppercase",
                                        paddingLeft: 0,
                                        transition:"0.3s",
                                        "&:hover": {
                                          textShadow:"0 1px  white",
                                            backgroundColor: "transparent"
                                        },
                                        // "&.MuiButton-root":{
                                        //     fontFamily: 'Solano Gothic Pro',
                                        // }
                                        // borderBottom: page.route === pathname ? '2px solid white' : 'none'
                                    }}
                                >
                                    {t(page.name)}
                                </Button>

                            ))}

                        </Box>
                    </Grid>
                    <Grid item textAlign={"right"} lg={2.3}>
                        <p className={'contact-info'}>Report Fraud, Abuse, Misconduct
                            Submit Social Or Environmental Complaint
                            Scam Alert
                            Terms Of Use
                        </p>
                    </Grid>
                    <Grid container justifyContent={{xs: "center", md: "space-between"}}
                          sx={{marginTop: "20px", marginBottom: {xs: "20px", md: "1px"}}}>
                        <Grid item sx={{marginBottom: {xs: "20px", md: "1px", color:"rgba(255,255,255,0.5)"}}}>
                            <p>© 2023 Институт Исследований Экономической Политики </p>
                        </Grid>
                        <Grid item xs={6} md={4} lg={3} className={"social-network-icons"}>
                            <a href="https://instagram.com"><img src={fb} alt="fb"/></a>
                            <a href="https://instagram.com"><img src={tw} alt="tw"/></a>
                            <a href="https://instagram.com"><img src={insta} alt="insta"/></a>
                            <a href="https://instagram.com"><img src={linkedin} alt="linkedin"/></a>
                            <a href="https://instagram.com"><img src={youtube} alt="youtube"/></a>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Footer;