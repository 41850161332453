import {combineReducers} from "redux";
import createSagaMiddleware from 'redux-saga';
import {rootSagas} from "./rootSagas";
import {configureStore} from "@reduxjs/toolkit";
import goalsSlice from "./slices/goalsSlice";
import newsSlice from "./slices/newsSlice";


const rootReducer = combineReducers({
    'goals': goalsSlice.reducer,
    'news':newsSlice.reducer,
});


const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

const store = configureStore({
    reducer: rootReducer,
    middleware,
    devTools: true,
});

sagaMiddleware.run(rootSagas);





export default store;