import React, {useEffect, useMemo, useState} from 'react';
import {Container, Grid, useMediaQuery} from "@mui/material";
import MiniSlider from "../../components/MiniSlider/MiniSlider";
import ShareComponent from "../../components/ShareComponent/ShareComponent";
import shareIcon from "../../assets/images/Base.svg";
import pdf from "../../assets/images/pdf-icon.svg";
import calendarIcon from "../../assets/images/calendar.svg";
import pin from "../../assets/images/pin.svg";
import users from "../../assets/images/users.svg";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import axiosApi from "../../axiosApi";
import Preloader from "../../components/Preloader/Preloader";
import DOMPurify from "dompurify";
import axios from "axios";
import fileDownload from "js-file-download";
import shareArrow from "../../assets/images/Arrow.svg";
import {mediaUrl} from "../../config";
import {events} from "../../paths";

const DetailedEvent = () => {
    const {t, i18n} = useTranslation();
    const mobileScreen = useMediaQuery('(max-width:504px)');
    const {id} = useParams();
    const dispatch = useDispatch();

    const [shareShow, setShareShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [event, setEvent] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const result = await axiosApi.get('/events/info/' + id + '/');
                setEvent(result.data);
                setLoading(false)
            } catch (err) {
                console.log(err)
                setLoading(false)
            }
        };
        fetchData();
    }, [id])


    const [yearStart, monthStart, dayStart] = event ? event.date_from.split('-').map(Number) : [0, 0, 0];

    const [yearEnd, monthEnd, dayEnd] = event ? event.date_to.split('-').map(Number) : [0, 0, 0];


    function getMonthName(monthNumber, language = 'en') {
        const months = {
            ru: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        };

        return months[language][monthNumber - 1];
    }


    const handleDownload = (url) => {
        const arr = url.split("/");
        const filename = arr[arr.length - 1];
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
            })

    }


    console.log(event)

    function getShorVersion(string) {
        const words = string.split(" ");
        if (words.length > 5) {
            return words.slice(0, 5).join(" ");
        } else {
            return string;
        }
    }


    const breadcrumbs = useMemo(() => {
        return (
            <p className="path-text">
                <Link to={'/'}>{t('main')}</Link> /
                <Link to={events}> {t('events')}</Link> /

                {event && (
                    <>
                        {i18n.language === 'ky' && (
                            <span>{getShorVersion(event.title_ky)}...</span>
                        )}
                        {i18n.language === 'ru' && (
                            <span>{getShorVersion(event.title_ru)}...</span>
                        )}
                        {i18n.language === 'en' && (
                            <span>{getShorVersion(event.title_en)}...</span>
                        )}
                    </>
                )}
            </p>
        )

    }, [i18n.language, event])


    return (
        <Container maxWidth={false} sx={{maxWidth: "1368px"}}>
            <Grid container justifyContent={"space-between"} alignItems={"center"}
                  sx={{marginTop: mobileScreen ? "20px" : "52px"}}>
                <Grid item>
                    <h4 className="block-title">{t('events')}</h4>
                </Grid>
                {/*<Grid item>*/}
                {/*    <button className={"show-more-btn"}>Показать все</button>*/}
                {/*</Grid>*/}

            </Grid>
            <div className="blue-line"></div>
            <div className={"line"}></div>

            {breadcrumbs}

            {loading ? (
                <Preloader/>
            ) : (
                <>
                    {event && (
                        <>

                            {i18n.language === 'ky' && (
                                <h3 className={'commission-title'}>{event.title_ky}</h3>
                            )}

                            {i18n.language === 'ru' && (
                                <h3 className={'commission-title'}>{event.title_ru}</h3>
                            )}

                            {i18n.language === 'en' && (
                                <h3 className={'commission-title'}>{event.title_en}</h3>
                            )}


                            <div className="commission-inner">
                                <div className="slider-float-box">
                                    <MiniSlider id={"news"} pictures={event.events_slider}/>
                                </div>


                                {i18n.language === 'ky' && (
                                    <div
                                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(event.text_ky)}}></div>
                                )}
                                {i18n.language === 'ru' && (
                                    <div
                                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(event.text_ru)}}></div>
                                )}
                                {i18n.language === 'en' && (
                                    <div
                                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(event.text_en)}}></div>
                                )}
                            </div>
                            {shareShow && (
                                <ShareComponent onClose={() => setShareShow(false)}/>
                            )}
                            <Grid container justifyContent={"space-between"} alignItems={"center"}
                                  sx={{marginBottom: "81px"}}>
                                <Grid item className="btns-parent" sx={{marginBottom: "20px"}}>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "left",
                                        marginRight: "50px"
                                    }} className={"share-btn-div"}>
                                        <button
                                            className="blue-btn"
                                            onClick={() => setShareShow(!shareShow)}
                                        >{t('share')}</button>

                                        <div style={{position: "relative", display: "flex", alignItems: "end"}}>
                                            <img src={shareIcon} alt="shareIcon" className={"share-icon"}/> <img
                                            src={shareArrow} alt="shareIcon" className={"share-arrow"}/>
                                        </div>
                                    </div>
                                    <button className="blue-btn"
                                            onClick={() => handleDownload(mediaUrl + event.file)}>{t('download')} <img
                                        src={pdf} alt="pdf icon"/></button>
                                </Grid>
                                <Grid item sx={{margin: "20px 0"}}>
                                    <div className={"event-inner"}>
                                        <img src={calendarIcon} alt="calendarIcon"/>

                                        {i18n.language === 'ky' && (
                                            <p>
                                                <span>{t('date')}: </span>{dayStart} {monthStart !== monthEnd && getMonthName(monthStart, 'ru')} - {dayStart !== dayEnd && dayEnd} {getMonthName(monthEnd, 'ru')}
                                                , {yearStart}</p>
                                        )}


                                        {i18n.language === 'ru' && (
                                            <p>
                                                <span>{t('date')}: </span>{dayStart} {monthStart !== monthEnd && getMonthName(monthStart, 'ru')} - {dayStart !== dayEnd && dayEnd} {getMonthName(monthEnd, 'ru')}
                                                , {yearStart}</p>
                                        )}

                                        {i18n.language === 'en' && (
                                            <p>
                                                <span>{t('date')}: </span>{dayStart} {monthStart !== monthEnd && getMonthName(monthStart, 'en')} - {dayStart !== dayEnd && dayEnd} {getMonthName(monthEnd, 'en')}
                                                , {yearStart}</p>
                                        )}

                                    </div>
                                    <div className={"event-inner"}>
                                        <img src={pin} alt="pin"/>
                                        {i18n.language === 'ky' && (
                                            <p><span>{t('location')}: </span>{event.place_ky}</p>
                                        )}
                                        {i18n.language === 'ru' && (
                                            <p><span>{t('location')}: </span>{event.place_ru}</p>
                                        )}
                                        {i18n.language === 'en' && (
                                            <p><span>{t('location')}: </span>{event.place_en}</p>
                                        )}

                                    </div>
                                    <div className={"event-inner"}>
                                        <img src={users} alt="users"/>
                                        <p><span>{t('organizer')}: </span>{event.host}</p>
                                    </div>
                                </Grid>
                            </Grid>

                        </>
                    )}


                </>
            )}


        </Container>
    );
};

export default DetailedEvent;