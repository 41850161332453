import React from 'react';
import {Line, Bar, PolarArea, Radar, Pie} from "react-chartjs-2";
import {useTranslation} from "react-i18next";
import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const ChartFromApi = ({data, obj, type, max}) => {
    const {i18n} = useTranslation()

     const plugin = {
        beforeInit: function (chart) {
            // Get reference to the original fit function
            const originalFit = chart.legend.fit

            // Override the fit function
            chart.legend.fit = function fit() {
                // Bind scope in order to use `this` correctly inside it
                originalFit.bind(chart.legend)()
                this.height += 35 // Change the height
            }
        }
    }

    const options = {
        plugins: {
            legend: {
                labels: {
                    boxWidth: 18, // set the width of the colored rectangle
                    boxHeight: 2, // set the height of the colored rectangle
                    padding: 15, // set the padding around the colored rectangle
                    font: {
                        size: 14
                    },
                },
                position: 'bottom',
                align: "end",
            },

        },

        scales: {
            y: {
                min: -2,
                max: max > 10 ? max+10 : max+2,
            },
        },
        maintainAspectRatio: false,
        responsive: true,
    }


    return (
            <div className={"chart-box bigger"}>
             <div style={{marginBottom:"20px"}}>
                 {i18n.language === 'ky' && (
                     <p>{obj.title_ky}</p>
                 )}

                 {i18n.language === 'ru' && (
                     <p>{obj.title_ru}</p>
                 )}

                 {i18n.language === 'en' && (
                     <p>{obj.title_en}</p>
                 )}
             </div>
                {type === 'line' && (
                    <Line
                        data={data}
                        options={options}
                        plugins={[plugin]}
                    >

                    </Line>
                )}

                {type === 'bar' && (
                    <Bar
                        data={data}
                        options={options}
                        plugins={[plugin]}
                    >

                    </Bar>
                )}

                {type === 'polar' && (
                    <PolarArea
                        data={data}
                        options={options}
                        plugins={[plugin]}
                    >

                    </PolarArea>
                )}

                {type === 'pie' && (
                    <Pie
                        data={data}
                        options={options}
                        plugins={[plugin]}
                    >

                    </Pie>
                )}

                {type === 'radar' && (
                    <Radar
                        data={data}
                        options={options}
                        plugins={[plugin]}
                    >

                    </Radar>
                )}


        </div>
    );
};

export default ChartFromApi;