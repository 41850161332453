import React from 'react';
import './styles.css';
import {
    FacebookIcon,
    FacebookShareButton, TelegramIcon, TelegramShareButton,
    VKIcon, VKShareButton, WhatsappIcon,
    WhatsappShareButton
} from "react-share";

const ShareComponent = ({onClose, url}) => {
    let shareLink = url? url : window.location.href;
    const title = "share"
    return (
            <div className="share-icons-box">
                <div className="mx-1">
                    <FacebookShareButton
                        url={shareLink}
                        quote={title}
                        className="network__share-button"
                    >
                        <FacebookIcon
                            size={"2rem"}
                            round
                            onClick={onClose}
                        />
                    </FacebookShareButton>
                </div>
                <div className="mx-1">
                    <TelegramShareButton
                        url={shareLink}
                        quote={title}
                        className="network__share-button"
                    >
                        <TelegramIcon
                            size={"2rem"}
                            round
                            onClick={onClose}
                        />
                    </TelegramShareButton>
                </div>
                <div className="mx-1">
                    <WhatsappShareButton
                        url={shareLink}
                        title={title}
                        separator=":: "
                        className="network__share-button"
                        onClick={onClose}
                    >
                        <WhatsappIcon size={"2rem"} round />
                    </WhatsappShareButton>
                </div>
                <div className="mx-1">
                    <VKShareButton
                        url={shareLink}
                        image={`${shareLink}`}
                        windowWidth={660}
                        windowHeight={460}
                        className="network__share-button"
                        onClick={onClose}
                    >
                        <VKIcon size={"2rem"} round />
                    </VKShareButton>
                </div>
            </div>
    );
};

export default ShareComponent;