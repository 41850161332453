import React from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Container, Grid} from "@mui/material";
import {mediaUrl} from "../../config";
import BigSlider from "../../components/BigSlider/BigSlider";
import NewsSingleCard from "../../components/NewsSingleCard/NewsSingleCard";
import EventSingleCard from "../../components/EventSingleCard/EventSingleCard";
import LibrarySingleCard from "../../components/LibrarySingleCard/LibrarySingleCard";
import SinglePlayer from "../../components/SinglePlayer/SInglePlayer";

const SearchResults = () => {
    const {t, i18n} = useTranslation();
    const searchResults = useSelector(state => state.goals.mainSearch);
    console.log(searchResults)


    return (
        <>
            {Object.keys(searchResults).length > 0 && (
                <Container maxWidth={false} sx={{maxWidth: "1368px", minHeight:"70vh", marginTop:"32px"}}>
                    {searchResults.news.length > 0 && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('news')}</h4>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>
                            <div style={{marginBottom:"20px"}}></div>

                            {searchResults.news.length > 9 ? (
                                <BigSlider id={"search"} news={searchResults.news}/>
                            ):(
                                <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                                    <Grid item xs={12} md={3.2}>
                                        {searchResults.news[0] && (
                                            <NewsSingleCard
                                                news={searchResults.news[0]}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={7.5} lg={5}>
                                        {searchResults.news[1] && (
                                            <NewsSingleCard
                                                news={searchResults.news[1]}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={3.2}>
                                        {searchResults.news[2] && (
                                            <NewsSingleCard
                                                news={searchResults.news[2]}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item sx={12} md={8.5}>
                                        {searchResults.news[3] && (
                                            <NewsSingleCard
                                                news={searchResults.news[3]}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item md={3.2}>
                                        {searchResults.news[4] && (
                                            <NewsSingleCard
                                                news={searchResults.news[4]}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item md={3.2}>
                                        {searchResults.news[5] && (
                                            <NewsSingleCard
                                                news={searchResults.news[5]}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item md={5}>
                                        {searchResults.news[6] && (
                                            <NewsSingleCard
                                                news={searchResults.news[6]}
                                            />
                                        )}

                                    </Grid>
                                    <Grid item md={3.2}>
                                        {searchResults.news[7] && (
                                            <NewsSingleCard
                                                news={searchResults.news[7]}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item md={8.5}>
                                        {searchResults.news[8] && (
                                            <NewsSingleCard
                                                news={searchResults.news[8]}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item md={3.2}>
                                        {searchResults.news[9] && (
                                            <NewsSingleCard
                                                news={searchResults.news[9]}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            )}

                        </>

                    )}

                    {searchResults.events.length > 0 && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('events')}</h4>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>
                            <div style={{marginBottom:"20px"}}></div>
                            <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                                {searchResults.events.map(event =>(
                                    <Grid item xs={12} sm={5.85} md={5.85} lg={3.85} key={event.id}>
                                        <EventSingleCard content={event}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </>

                    )}

                    {searchResults.researchLibrary.length > 0 && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('research')}</h4>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>
                            <div style={{marginBottom:"20px"}}></div>
                            <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                                {searchResults.researchLibrary.map(lib =>(
                                    <Grid item xs={12} sm={5.8} md={3.3} key={lib.id}>
                                        <LibrarySingleCard content={lib}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </>

                    )}
                    {searchResults.otherLibrary.length > 0 && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('national')} </h4>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>
                            <div style={{marginBottom:"20px"}}></div>
                            <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                                {searchResults.otherLibrary.map(lib =>(
                                    <Grid item xs={12} sm={5.8} md={3.3} key={lib.id}>
                                        <LibrarySingleCard content={lib}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                    {searchResults.videos.length > 0 && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('videos')} </h4>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>
                            <div style={{marginBottom:"20px"}}></div>
                            <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                                {searchResults.videos.map(video =>(
                                    <Grid item xs={12} sm={12} md={5.8} lg={3.85}>
                                            <SinglePlayer video={video}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}

                    {searchResults.govAgency.length > 0 && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('government')} </h4>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>
                            <div style={{marginBottom:"20px"}}></div>
                            <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                                {searchResults.govAgency.map(res =>(
                                    <Grid container className={"resource-card"} justifyContent={"space-between"}
                                          alignItems={'center'}>
                                        <Grid item xs={12} sm={2} md={2} container
                                              justifyContent={{xs: "center", sm: "space-between"}}
                                              sx={{marginBottom: {xs: "20px", sm: "0"}}}>
                                            <img src={mediaUrl + res.icon} alt="emblem" className='emblem'/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5.5} container
                                              justifyContent={{xs: "center", sm: "space-between"}}
                                              sx={{marginBottom: {xs: "20px", sm: "0"}}} flexDirection={'column'}>

                                            {i18n.language === 'ky' && (
                                                <p className={'resource-title'}>{res.title_ky}</p>
                                            )}
                                            {i18n.language === 'ru' && (
                                                <p className={'resource-title'}>{res.title_ru}</p>
                                            )}

                                            {i18n.language === 'en' && (
                                                <p className={'resource-title'}>{res.title_en}</p>
                                            )}

                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4.5} className="resource-contact" container
                                              justifyContent={{xs: "center", sm: "right"}}
                                              sx={{marginBottom: {xs: "20px", sm: "0"}}} flexDirection={'column'}>
                                            <a href={res.link} target="_blank" rel="noopener noreferrer">{res.link}</a>

                                            {i18n.language === 'ky' && (
                                                <p>{res.address_ky}</p>
                                            )}
                                            {i18n.language === 'ru' && (
                                                <p>{res.address_ru}</p>
                                            )}
                                            {i18n.language === 'en' && (
                                                <p>{res.address_en}</p>
                                            )}
                                            <p>{t('phone')}: {res.phone}</p>
                                            <p>{t('email')}: {res.email}</p>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}

                    {searchResults.intAgency.length > 0 && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('international')} </h4>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>
                            <div style={{marginBottom:"20px"}}></div>
                            <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                                {searchResults.intAgency.map(res =>(
                                    <Grid container className={"resource-card"} justifyContent={"space-between"}
                                          alignItems={'center'}>
                                        <Grid item xs={12} sm={2} md={2} container
                                              justifyContent={{xs: "center", sm: "space-between"}}
                                              sx={{marginBottom: {xs: "20px", sm: "0"}}}>
                                            <img src={mediaUrl + res.icon} alt="emblem" className='emblem'/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5.5} container
                                              justifyContent={{xs: "center", sm: "space-between"}}
                                              sx={{marginBottom: {xs: "20px", sm: "0"}}} flexDirection={'column'}>

                                            {i18n.language === 'ky' && (
                                                <p className={'resource-title'}>{res.title_ky}</p>
                                            )}
                                            {i18n.language === 'ru' && (
                                                <p className={'resource-title'}>{res.title_ru}</p>
                                            )}

                                            {i18n.language === 'en' && (
                                                <p className={'resource-title'}>{res.title_en}</p>
                                            )}

                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4.5} className="resource-contact" container
                                              justifyContent={{xs: "center", sm: "right"}}
                                              sx={{marginBottom: {xs: "20px", sm: "0"}}} flexDirection={'column'}>
                                            <a href={res.link} target="_blank" rel="noopener noreferrer">{res.link}</a>

                                            {i18n.language === 'ky' && (
                                                <p>{res.address_ky}</p>
                                            )}
                                            {i18n.language === 'ru' && (
                                                <p>{res.address_ru}</p>
                                            )}
                                            {i18n.language === 'en' && (
                                                <p>{res.address_en}</p>
                                            )}
                                            <p>{t('phone')}: {res.phone}</p>
                                            <p>{t('email')}: {res.email}</p>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}

                    {searchResults.othAgency.length > 0 && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('international')} </h4>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>
                            <div style={{marginBottom:"20px"}}></div>
                            <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                                {searchResults.othAgency.map(res =>(
                                    <Grid container className={"resource-card"} justifyContent={"space-between"}
                                          alignItems={'center'}>
                                        <Grid item xs={12} sm={2} md={2} container
                                              justifyContent={{xs: "center", sm: "space-between"}}
                                              sx={{marginBottom: {xs: "20px", sm: "0"}}}>
                                            <img src={mediaUrl + res.icon} alt="emblem" className='emblem'/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5.5} container
                                              justifyContent={{xs: "center", sm: "space-between"}}
                                              sx={{marginBottom: {xs: "20px", sm: "0"}}} flexDirection={'column'}>

                                            {i18n.language === 'ky' && (
                                                <p className={'resource-title'}>{res.title_ky}</p>
                                            )}
                                            {i18n.language === 'ru' && (
                                                <p className={'resource-title'}>{res.title_ru}</p>
                                            )}

                                            {i18n.language === 'en' && (
                                                <p className={'resource-title'}>{res.title_en}</p>
                                            )}


                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4.5} className="resource-contact" container
                                              justifyContent={{xs: "center", sm: "right"}}
                                              sx={{marginBottom: {xs: "20px", sm: "0"}}} flexDirection={'column'}>
                                            <a href={res.link} target="_blank" rel="noopener noreferrer">{res.link}</a>

                                            {i18n.language === 'ky' && (
                                                <p>{res.address_ky}</p>
                                            )}
                                            {i18n.language === 'ru' && (
                                                <p>{res.address_ru}</p>
                                            )}
                                            {i18n.language === 'en' && (
                                                <p>{res.address_en}</p>
                                            )}
                                            <p>{t('phone')}: {res.phone}</p>
                                            <p>{t('email')}: {res.email}</p>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}


                </Container>
            )}

                </>
    );
};

export default SearchResults;